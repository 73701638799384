*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
	scrollbar-color: #8e8e8e #fff;
	scrollbar-width:thin;
}
body {
	background-color: #2D2E32;
	font-family: Poppins, sans-serif;
	position: relative 
}
html {
	font-size: 50%;
}
 
.carousel-item {
	transition: opacity 0.5s ease-in-out;
  }

  
@media (min-width: 768px) {
	html {
		/* font-size: 62.5%; */
		font-size: 60%;
   }
}


@keyframes slideFromRight {
	0% {
		transform: translateX(100%);
   }
	100% {
		transform: translateX(0);
   }
}
.slide-from-right {
	animation: slideFromRight 0.5s ease-in-out backwards;
	transform: translateX(100%);
	transition: transform 0.3s ease-in-out;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	background-color: #2D2E32 
} 

 

@import url('https://fonts.googleapis.com/css2?family=Carlito&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carlito&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:wght@300;400;500;600;700&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

 
	 
 
 